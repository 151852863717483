import React, { Component } from 'react';
import { arrayOf, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import css from './ToggleBooleanFilter.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class ToggleBooleanFilter extends Component {
  constructor(props) {
    super(props);
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  handleToggleChange() {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const currentValue =
      this.props.initialValues && this.props.initialValues[queryParamName] === 'true';
    const newValue = !currentValue ? 'true' : null;
    onSelect({ [queryParamName]: newValue });
  }

  render() {
    const { rootClassName, className, label, queryParamNames, initialValues } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue = initialValues && initialValues[queryParamName] === 'true';

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <span className={css.label}>{label}</span>
        <div className={css.toggleContainer}>
          <label className={css.switch}>
            <input type="checkbox" checked={initialValue} onChange={this.handleToggleChange} />
            <span className={css.slider}></span>
          </label>
        </div>
      </div>
    );
  }
}

ToggleBooleanFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
};

ToggleBooleanFilter.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  initialValues: object,
};

export default ToggleBooleanFilter;
